.main {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: row;
  }
  .main{
    background-position: center;
    background-size: cover;
  }

  .drop{
    background: linear-gradient(-135deg,#b00251ff,#c90251ff);
    backdrop-filter:  blur(0.5rem);
    -webkit-backdrop-filter: blur(0.5rem);
  }
  
  .side-bar,.chat-head,.to-send,.welcome-box{
    background: rgb(255, 255, 255);
    background: linear-gradient(-135deg,#b00251ff,#c90251ff);
    backdrop-filter:  blur(0.5rem);
    min-width: 320px;
  }
  .pdp{
      width: 60px;
      height: 60px;
  }
  .pdp-geant{
    width: auto;
    height: 200px;
  }
  .search{
      padding-left: 16px;
      padding-right: 40px;
      border:none;
      padding-top: 6px;
      padding-bottom: 6px;
  }
  .search-icon{
    width: 30px;
    height: 30px;
    background: linear-gradient(-135deg,#b00251ff,#c90251ff);
    right: 18px;
    top: 2px;
  }
  .pdp-chats{
    width: 50px !important ;
    height: 50px !important ;
  }
  .text-chats{
      max-width: 200px;
      max-height: 20px;
  }
  .to-send{
      bottom: 0px;
  }
  .emoji-icon{
    width: 30px;
    height: 30px;
    background: linear-gradient(-135deg,#b00251ff,#c90251ff);
    left: 18px;
    top: 18px;
  }
  .circle-icon{
    width: 35px;
    height: 35px;
  }

  .human-chats-active{
    background: linear-gradient(-135deg,#b00251ff,#c90251ff);
    backdrop-filter:  blur(0.5rem);
    -webkit-backdrop-filter: blur(0.5rem);
  }
  .human-chats:hover{
    background: linear-gradient(-135deg,#b00251ff,#c90251ff);
    backdrop-filter:  blur(0.5rem);
    -webkit-backdrop-filter: blur(0.5rem);
    color: #f9f9f9ff !important;
  }
  .texto{
    padding-left: 38px;
    padding-right: 40px;
    border:none;
    padding-top: 6px;
    padding-bottom: 6px;
}
.text-linear{
  background: linear-gradient(-135deg,#b00251ff,#c90251ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.w3-margin-top-small{
  margin-top: 8px;
}
.is-grey{
  background: #313131ff
}
.is-color-grey{
  color: #313131ff
}

.ll{
  font-size: 20px !important;
}